import { createStore } from 'redux';
import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import customizationReducer from './customizationReducer';

const store = createStore(reducer);
const persister = 'Free';

export { store, persister };

export const mystore = configureStore({
    reducer: {
        user: userReducer,
        customization: customizationReducer
    }
});
