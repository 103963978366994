// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Bots',
    type: 'group',
    children: [
        {
            id: 'util-typography',
            title: 'Create Bot',
            type: 'item',
            url: '/bots/Createbot',
            icon: icons.IconTypography,
            breadcrumbs: false
        },
        {
            id: 'util-color',
            title: 'Bots',
            type: 'item',
            url: '/bots/Bots',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'tutorials',
            title: 'Tutorials',
            type: 'collapse',
            icon: icons.IconKey,
            children: [
                {
                    id: 'createBot',
                    title: 'createBot',
                    type: 'item',
                    url: '/tut/createBot'
                },
                {
                    id: 'tradingView',
                    title: 'tradingView',
                    type: 'item',
                    url: '/tut/tradingView'
                }
            ]
        }
    ]
};

export default utilities;
