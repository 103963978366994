import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';
import AOS from 'aos';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import { auth } from './firebase';
import { logout, login, selectUser } from './features/userSlice';
import { useEffect } from 'react';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((userAuth) => {
            if (userAuth) {
                //Logged in
                dispatch(
                    login({
                        uid: userAuth.uid,
                        email: userAuth.email
                    })
                );
            } else {
                //Logged out
                dispatch(logout());
            }
        });

        return unsubscribe;
    }, [dispatch]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
