import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

//bot routing
const Createbot = Loadable(lazy(() => import('views/bots/Createbot')));
const Bots = Loadable(lazy(() => import('views/bots/Bots')));
const Editbot = Loadable(lazy(() => import('views/bots/Editbot')));

//tutorial routing
const Createbottut = Loadable(lazy(() => import('views/tut/createBot')));
const Tradingviewtut = Loadable(lazy(() => import('views/tut/tradingView')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'bots',
            children: [
                {
                    path: 'Createbot',
                    element: <Createbot />
                }
            ]
        },
        {
            path: 'bots',
            children: [
                {
                    path: 'Bots',
                    element: <Bots />
                }
            ]
        },

        {
            path: 'bots',
            children: [
                {
                    path: 'edit/:id',
                    element: <Editbot />
                }
            ]
        },
        {
            path: 'tut',
            children: [
                {
                    path: 'createBot',
                    element: <Createbottut />
                }
            ]
        },
        {
            path: 'tut',
            children: [
                {
                    path: 'tradingView',
                    element: <Tradingviewtut />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        }
    ]
};

export default MainRoutes;
