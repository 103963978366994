// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
// import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyARC1lD3j30nUZLUa0ybWpZGHOG_4TMzeg',
    authDomain: 'tradingwizz-build.firebaseapp.com',
    projectId: 'tradingwizz-build',
    storageBucket: 'tradingwizz-build.appspot.com',
    messagingSenderId: '295989542532',
    appId: '1:295989542532:web:52c34ff4189eb0bb994cd1',
    measurementId: 'G-441B1XLJ4Z'
};

// Initialize Firebase
// const analytics = getAnalytics(firebaseApp);
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth };
export default db;
