import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// sample page routing
const LandingLayout = Loadable(lazy(() => import('../layout/LandingLayoutInsane/index')));

// ==============================|| MAIN ROUTING ||============================== //

const LandingRoutes = {
    path: '/',
    element: <LandingLayout />
};

export default LandingRoutes;
